import React, { useState } from "react";
import { Modal, Box, Button, Backdrop, Alert } from "@mui/material";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import useWindowSize from "../../Hooks/useWindowSize";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneLoginModal({ open, handleClose, openOTP, setIdentifier }) {
  const [number, setNumber] = useState("");
  const [incorrect, setIncorrect] = useState(false);
  const [correct, setCorrect] = useState(false);
  const { width } = useWindowSize();
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("http://localhost:3001/api/login", {
        number,
      });

      console.log("Login successful:", response.data);
      if (response.status === 200) {
        setCorrect(true);
        setTimeout(() => {
          handleClose();
          setIdentifier(number);
          openOTP();
        }, 2000);
      } else {
        setIncorrect(true);
      }
    } catch (error) {
      // Handle error response
      setIncorrect(true);
      console.error("Error logging in:", error);
    }
  };

  const handleChange = (newValue) => {
    setNumber(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.75)", // Customize the backdrop color and opacity here
          },
        },
      }}
    >
      <Box
        sx={{
          width: width > 767 ? 420 : 328,
          height: width > 767 ? 342 : 282,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            width: width > 767 ? 300 : 204,
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: width > 767 ? "28px" : "20px",
              display: "block",
            }}
          >
            Almost there!
          </span>
          <span
            style={{
              fontWeight: 300,
              fontSize: width > 767 ? "20px" : "14px",
              color: "#1B2130BF",
              display: "block",
              marginBottom: width > 767 ? "32px" : "24px",
            }}
          >
            Login to see a result
          </span>

          <form onSubmit={handleSubmit}>
            <PhoneInput
              country={"in"} // Default country code
              value={number}
              onChange={handleChange}
              enableSearch={true} // Allows searching for countries
              disableDropdown={false} // Disables the dropdown if set to true
              placeholder="Enter phone number"
              preferredCountries={["in","us","uae"]} // Preferred countries shown at the top of the dropdown
              inputStyle={{ width: "100%" }} // Custom styling for the input field
              buttonStyle={{ backgroundColor: "lightblue" }} // Custom styling for the dropdown button
            />

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                },
                textTransform: "capitalize",
                marginTop: "24px",
              }}
              fullWidth
              onClick={handleSubmit}
            >
              Get OTP
            </Button>
          </form>
          {incorrect && <Alert severity="error">Server Error!</Alert>}
          {correct && <Alert severity="success">Succedfully sent OTP</Alert>}
        </Box>
      </Box>
    </Modal>
  );
}

export default PhoneLoginModal;
