import React, { useState } from "react";
import styles from "./Footer.module.css";
import odoo from "../Assets/odoo.svg";
import quickbooks from "../Assets/quickbooks.svg";
import zohobooks from "../Assets/zohobooks.svg";
import { Button } from "@mui/material";
import useWindowSize from "../Hooks/useWindowSize";
import ContactModal from "./Modals/ContactModal";

function Footer() {
  const { width } = useWindowSize();

  const [openContact,setOpenContact] = useState(false)

  const handleClick  = ()=>{
    setOpenContact(true)
  }

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["footer-container"]}>
          <div className={styles["item item-1"]}>
            <span className={styles["span1"]}>
              Unleash the Full Potential of Your Software OCR; Integration is
              Just a Click Away!{" "}
            </span>
          </div>
          <div className={styles["item item-2"]}>
            <div className={styles["hero-list"]}>
              <img src={odoo} alt="odoo logo" />
              <img src={quickbooks} alt="odoo logo" />
              <img src={zohobooks} alt="odoo logo" />
              <div className={styles["hero-list-item2-cover"]}>
                <span className={styles["hero-list-item2"]}>Custom API</span>
              </div>
            </div>
          </div>
          <div className={styles["item item-3"]}>
            <Button
              variant="contained"
              fullWidth={width < 767 ? true : false}
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                },
              }}
              onClick={handleClick}
            >
              Integrate Now
            </Button>
          </div>
        </div>
      </div>
      <ContactModal
        open={openContact}
        handleClose={() => setOpenContact(false)}
      />
    </>
  );
}

export default Footer;
