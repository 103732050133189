import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Backdrop,
  Alert,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import useWindowSize from "../../Hooks/useWindowSize";

function OTPModal({
  open,
  handleClose,
  identifier,
  setOtpValue,
  correct,
  incorrect,
  resendTrigger,
}) {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { width } = useWindowSize();
  const [isLoading,setIsLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();

    setOtpValue(otp);
    setLoading(true);
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };



  const handleResend = async (event) => {

    const formData = new FormData();
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    formData.append("username", identifier);
    formData.append("password", resendTrigger);
  
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://data-extraction-login-i5menkivkq-el.a.run.app",
        formData,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        if (response.data.jwt_token.length === 4) {
          setIsLoading(false)
        } else {
          console.log(response.data.jwt_token)
          setIsLoading(false);
        }
      } 
    } catch (error) {
      // Handle error response;
      console.error("Error ", error);
   
    }
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.75)", // Customize the backdrop color and opacity here
          },
        },
      }}
    >
      <Box
        sx={{
          width: width > 767 ? 420 : 328,
          height: width > 767 ? 342 : 282,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            width: width > 767 ? 300 : 204,
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: width > 767 ? "28px" : "20px",
              display: "block",
            }}
          >
            We've Sent an OTP on
          </span>
          <span
            style={{
              fontWeight: 300,
              fontSize: width > 767 ? "20px" : "14px",
              color: "#1B2130BF",
              display: "block",
              marginBottom: width > 767 ? "32px" : "24px",
            }}
          >
            {identifier}
          </span>

          <form onSubmit={handleSubmit}>
            <MuiOtpInput
              value={otp}
              onChange={handleChange}
              TextFieldsProps={{
                placeholder: "-",
                size: "small",
                sx: { marginBottom: "30px" },
              }}
            />
            <span>
              <span style={{ fontSize: "14px", color: "#1B2130BF" }}>
                Didn’t receive OTP?
              </span>
              <Button
                size="small"
                sx={{ textTransform: "capitalize", color: "#303D88" }}
                onClick={handleResend}
              >
                <span>Resend {isLoading && <CircularProgress size={16} sx={{marginLeft: "10px"}}/>}</span>
              </Button>
            </span>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                },
                textTransform: "capitalize",
              }}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
          {incorrect && <Alert severity="error">incorrect OTP</Alert>}
          {correct && <Alert severity="success">successfully Logged In</Alert>}
          {!(incorrect || correct) && loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default OTPModal;
