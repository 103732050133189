import React, { useState, useEffect, useCallback } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./Home.module.css";
import odoo from "../Assets/odoo.svg";
import quickbooks from "../Assets/quickbooks.svg";
import zohobooks from "../Assets/zohobooks.svg";
import { Button, LinearProgress, styled, IconButton } from "@mui/material";
import FileArrowUp from "@mui/icons-material/UploadFile";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Result from "./Result";
import Footer from "./Footer";
import LoginModal from "./Modals/LoginModal";
import RegistrationModal from "./Modals/RegistrationModal";
import OTPModal from "./Modals/OtpModal";
import PhoneLoginModal from "./Modals/PhoneLoginModal";
import { jwtDecode } from "jwt-decode";
import useWindowSize from "../Hooks/useWindowSize";

function Home() {
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [resultResponse, setResultResponse] = useState("");
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [identifier, setIdentifier] = useState(null);
  const [otpValue, setOtpValue] = useState("");
  const [correct, setCorrect] = useState(false);
  const [inCorrect, setIncorrect] = useState(false);
  const [resendTrigger, setResendTrigger] = useState(false);

  const { width } = useWindowSize();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {};

  const handleDrop = (e) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleSubmit = useCallback(async () => {
    const formData = new FormData();
    formData.append("invoice", uploadedFile);
    setIncorrect(false);
    setCorrect(false);
    setUploading(true);
    setUploadComplete(false);
    setProgress(0);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      if (localStorage.getItem("authToken")) {
        const token = localStorage.getItem("authToken");
        formData.append("authentication_code", token);
        const userId = decodeJWT(token);
        formData.append("username", userId);
      } else if (otpValue.length > 0) {
        console.log("here it is!");
        formData.append("authentication_code", otpValue);
        formData.append("username", identifier);
      } else {
        formData.append("authentication_code", "''");
        formData.append("username", "''");
      }

      const response = await axios.post(
        "https://data-extraction-jsonformat-i5menkivkq-el.a.run.app",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(progress);
            const uploadedMB = progressEvent.loaded / (1024 * 1024);
            setUploadedSize(uploadedMB.toFixed(2));
          },
          headers: headers,
        }
      );

      if (response.status === 200) {
        setUploadComplete(true);
        setResultResponse(response.data.Data);
        setCorrect(true);
        setIncorrect(false);
        setIsAuthenticated(true);
        setTimeout(() => {
          setOpenOTP(false);
        }, 2000);
      }
      console.log("Upload successful:", response.data.Data);
    } catch (error) {
      setUploadComplete(false);
      if (error.status === 401) {
        localStorage.removeItem("authToken");
        if (!openOTP) {
          setOpenLogin(true);
        } else {
          setIncorrect(true);
          setCorrect(false);
        }
      }
    }
  }, [uploadedFile, otpValue]);

  useEffect(() => {
    if (uploadedFile) {
      handleSubmit();
    }
  }, [uploadedFile, handleSubmit]);

  const handleFiles = (files) => {
    const file = files[0]; // Handle only one file for simplicity
    const maxSize = 50 * 1024 * 1024;
    const allowedTypes = ["application/pdf", "image/jpeg"];

    if (file.size > maxSize) {
      alert("File size exceeds 50 MB. Please upload a smaller file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      alert("Only PDF and JPEG files are allowed. Please upload a valid file.");
      return;
    }

    setUploadedFile(file);
    setFileUrl(URL.createObjectURL(file));
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    handleFiles(files);
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const removeFile = () => {
    setUploadedFile(null);
    setFileUrl("");
    setProgress(0);
    setUploading(false);
    setUploadComplete(false);
  };

  const decodeJWT = (token) => {
    try {
      // Decode the JWT token
      const decodedToken = jwtDecode(token);

      // Extract the user_id claim
      const userId = decodedToken.user_id;

      return userId;
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      
      <div className={styles["hero-content"]}>
        <div className={styles["hero-text"]}>
          <span>
            Streamline document <br />
            <span>
              <span className={styles["span-backgrounds"]}>processing</span>{" "}
              <span className={styles["hero-faster"]}>faster</span>{" "}
              <span className={styles["span-backgrounds"]}>than ever!</span>
              <br />
            </span>
            Watch it in action.
          </span>
        </div>
        <div className={styles["hero-list"]}>
          <span className={styles["hero-list-item1"]}>Integrate with</span>
          <img src={odoo} alt="odoo logo" />
          <img src={quickbooks} alt="odoo logo" />
          <img src={zohobooks} alt="odoo logo" />
          <div className={styles["hero-list-item2-cover"]}>
            <span className={styles["hero-list-item2"]}>Custom API</span>
          </div>
        </div>
      </div>
      
      <div className={styles["upload-main"]}>
      <div class={styles["background-div"]}></div>
        <div
          className={styles["upload-sub"]}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className={styles["upload-content"]}>
            <div className={styles[`upload-content-text`]}>
              <VisuallyHiddenInput
                type="file"
                id="fileInput"
                onChange={handleFileInputChange}
              />
              <span className={styles["upload-content-text-span"]}>
                Drop your file here
              </span>
              <span className={styles["upload-content-text-or"]}>or</span>

              <Button
                component="label"
                variant="contained"
                size="18px"
                sx={{
                  padding: "12px",
                  backgroundColor: "#303D88",
                  "&:hover": {
                    backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                  },

                  textTransform: "none",
                  fontFamily: "inherit",
                  fontWeight: "400",
                  color: "#fff",
                }}
                startIcon={<FileArrowUp />}
                onClick={handleClick}
              >
                Upload a file
              </Button>
            </div>
            <div>
              <span className={styles["upload-content-text-sub"]}>
                Maximum size allowed is 10 MB.Supported formats are: PDF,JPEG
              </span>
            </div>
            {uploading && (
              <div className={styles["progress-bar"]}>
                <div className={styles["icon-container"]}>
                  <InsertDriveFileIcon
                    sx={{
                      width: width > 767 ? "60px" : "40px",
                      height: width > 767 ? "58px" : "48px",
                      color: "#303D88",
                    }}
                  />
                </div>
                <div className={styles["progress-bar-content"]}>
                  <div className={styles["progress-bar-header"]}>
                    <span className={styles["truncate"]}>
                      {uploadedFile?.name}
                    </span>
                    <IconButton onClick={removeFile}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    sx={{
                      height: "8px",
                      color: "#303D88",
                    }}
                    value={progress}
                    color="inherit"
                  />
                  {!uploadComplete || !isAuthenticated ? (
                    <span>{uploadedSize} MB uploaded. Processing......</span>
                  ) : (
                    <span>
                      {uploadedSize} MB uploaded{" "}
                      <span style={{ color: "#18B100" }}>successfully</span>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {uploadComplete && isAuthenticated && (
        <>
          <Result
            fileUrl={fileUrl}
            data={resultResponse}
            docSize={uploadedSize}
            fileName={uploadedFile?.name}
          />
          <Footer />
        </>
      )}

      <LoginModal
        open={openLogin}
        handleClose={() => setOpenLogin(false)}
        openRegister={() => setOpenRegister(true)}
        setIdentifier={setIdentifier}
        handleFiles={() => handleSubmit()}
        setAuthentication={() => setIsAuthenticated(true)}
        openOTP={() => setOpenOTP(true)}
      />
      <RegistrationModal
        open={openRegister}
        handleClose={() => setOpenRegister(false)}
        openOTP={() => setOpenOTP(true)}
        openLogin={() => setOpenLogin(true)}
        setIdentifier={setIdentifier}
        setResendTrigger={setResendTrigger}
      />

      <OTPModal
        open={openOTP}
        handleClose={() => setOpenOTP(false)}
        handleFiles={() => handleSubmit()}
        identifier={identifier}
        setOtpValue={setOtpValue}
        correct={correct}
        incorrect={inCorrect}
        resendTrigger={resendTrigger}
      />
      <PhoneLoginModal
        open={openPhone}
        handleClose={() => setOpenPhone(false)}
        openOTP={() => setOpenOTP(true)}
        setIdentifier={setIdentifier}
        handleFiles={() => handleSubmit()}
        correct={correct}
        inCorrect={inCorrect}
      />
    </>
  );
}

export default Home;
