import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Backdrop,
  FormControl,
  FormHelperText,
  Alert,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import useWindowSize from "../../Hooks/useWindowSize";

function LoginModal({
  open,
  handleClose,
  openRegister,
  setAuthentication,
  setIdentifier,
  handleFiles,
  openOTP
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isEmailPristine, setIsEmailPristine] = useState(true);
  const [isPasswordPristine, setIsPasswordPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState(false);

  const { width } = useWindowSize();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setCorrect(false);
    setIncorrect(false);
    setLoading(true);
    const formData = new FormData();
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    formData.append("username", username);
    formData.append("password", password);
    setIdentifier(username);

    try {
      const response = await axios.post(
        "https://data-extraction-login-i5menkivkq-el.a.run.app",
        formData,
        {
          headers: headers,
        }
      );
      setLoading(false);
      console.log("Login successful:", response.data);
      if (response.status === 200) {
        setCorrect(true);
        setIncorrect(false);
        const { jwt_token } = response.data;
        if (jwt_token.length > 4) {
          localStorage.setItem("authToken", jwt_token);
          setTimeout(() => {
            handleClose();
            setAuthentication();
            setIdentifier(username);
            handleFiles();
          }, 2000);
        } else if(jwt_token.length <= 4){
          setIncorrect(false);
          setCorrect(true);
          setTimeout(() => {
            handleClose();
            openOTP();
          }, 2000);
        }
        else {
          setIncorrect(true);
          setCorrect(false);
        }
      } else {
        setCorrect(false);
        setIncorrect(true);
      }
    } catch (error) {
      setLoading(false);
      setCorrect(false);
      setIncorrect(true);
    }
  };

  const handleUserName = (event) => {
    const entry = event.target.value;
    setUsername(entry);
    if (isEmailPristine) {
      setIsEmailPristine(false);
    }
    if (entry.length > 0 && entry.includes("@")) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  const handleEmailBlur = () => {
    // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
    if (isEmailPristine) {
      setIsEmailPristine(false);
    }
  };

  const handlePasswordBlur = () => {
    // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
    if (isPasswordPristine) {
      setIsPasswordPristine(false);
    }
  };

  const handlePassword = (event) => {
    const entry = event.target.value;
    const strongPasswordRegex = /^.{8,}$/;
    setPassword(entry);
    if (isPasswordPristine) {
      setIsPasswordPristine(false);
    }

    if (strongPasswordRegex.test(entry)) {
      setPasswordIsValid(true);
    } else {
      setPasswordIsValid(false);
    }
  };

  const handleRegister = () => {
    handleClose();
    openRegister();
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.75)", // Customize the backdrop color and opacity here
          },
        },
      }}
    >
      <Box
        sx={{
          width: width > 767 ? 520 : 328,
          height: width > 767 ? 562 : 376,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            width: width > 767 ? 400 : 340,
            position: "absolute",
            top: width > 767 ? "40%" : "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: width > 767 ? "48px" : "24px",
              display: "block",
            }}
          >
            Almost There!
          </span>
          <span
            style={{
              fontWeight: 300,
              fontSize: width > 767 ? "20px" : "14px",
              color: "#1B2130BF",
              display: "block",
              marginBottom: "24px",
            }}
          >
            Login to see a result
          </span>

          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Email Address
              </FormHelperText>
              <TextField
                error={!emailIsValid && !isEmailPristine}
                placeholder="Example@gmail.com"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ marginTop: 0 }}
                value={username}
                onChange={(e) => handleUserName(e)}
                onBlur={handleEmailBlur}
                helperText={
                  !emailIsValid && !isEmailPristine ? "Enter a valid email" : ""
                }
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "48px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Password
              </FormHelperText>
              <TextField
                error={!isPasswordPristine && !passwordIsValid}
                placeholder="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ marginTop: 0 }}
                value={password}
                onChange={(e) => handlePassword(e)}
                onBlur={handlePasswordBlur}
                helperText={
                  !isPasswordPristine && !passwordIsValid
                    ? "password must be strong"
                    : ""
                }
              />
            </FormControl>
            {incorrect && <Alert severity="error">Unauthorized</Alert>}
            {correct && (
              <Alert severity="success">successfully Logged In</Alert>
            )}

            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                size="small"
                sx={{ textTransform: "capitalize", color: "#303D88" }}
                onClick={handleRegister}
              >
                register instead
              </Button>
              <Button
                size="small"
                sx={{ textTransform: "capitalize", color: "#303D88" }}
                onClick={handleRegister}
              >
                forgot password?
              </Button>
            </span>

            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Button
              disabled={!(emailIsValid && passwordIsValid)}
              variant="contained"
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                },

                textTransform: "capitalize",
              }}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default LoginModal;
