import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Backdrop,
  FormControl,
  FormHelperText,
  Alert,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import useWindowSize from "../../Hooks/useWindowSize";

function RegistrationModal({
  open,
  handleClose,
  openOTP,
  setIdentifier,
  openLogin,
  setResendTrigger
}) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [userIsValid, setUserIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [isEmailPristine, setIsEmailPristine] = useState(true);
  const [isUserPristine, setIsUserPristine] = useState(true);
  const [isPasswordPristine, setIsPasswordPristine] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const { width } = useWindowSize();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    formData.append("username", email);
    formData.append("password", password);
    setIdentifier(email);
    setResendTrigger(password)
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://data-extraction-login-i5menkivkq-el.a.run.app",
        formData,
        {
          headers: headers,
        }
      );

      setIsLoading(false);
      if (response.status === 200) {
        if (response.data.jwt_token.length > 0) {
          setIncorrect(true);
          setCorrect(false);
        } else {
          setIncorrect(false);
          setCorrect(true);
          setTimeout(() => {
            handleClose();
            openOTP();
          }, 2000);
        }
      } else {
        setIncorrect(true);
        setCorrect(false);
      }
    } catch (error) {
      // Handle error response
      setIncorrect(true);
      console.error("Error registering user:", error);
      setServerError(true);
    }
  };

  const handleLogin = () => {
    handleClose();
    openLogin();
  };

  const handleEmail = (event) => {
    const entry = event.target.value;
    setEmail(entry);
    if (isEmailPristine) {
      setIsEmailPristine(false);
    }
    if (entry.length > 0 && entry.includes("@")) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  const handleUsername = (event) => {
    const entry = event.target.value;
    setUsername(entry);
    if (isUserPristine) {
      setIsUserPristine(false);
    }
    if (entry.length > 0) {
      setUserIsValid(true);
    } else {
      setUserIsValid(false);
    }
  };

  const handleEmailBlur = () => {
    // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
    if (isEmailPristine) {
      setIsEmailPristine(false);
    }
  };

  const handleUserBlur = () => {
    // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
    if (isUserPristine) {
      setIsUserPristine(false);
    }
  };

  const handlePasswordBlur = () => {
    // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
    if (isPasswordPristine) {
      setIsPasswordPristine(false);
    }
  };

  const handlePassword = (event) => {
    const entry = event.target.value;
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
    setPassword(entry);
    if (isPasswordPristine) {
      setIsPasswordPristine(false);
    }

    if (strongPasswordRegex.test(entry)) {
      setPasswordIsValid(true);
    } else {
      setPasswordIsValid(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.75)", // Customize the backdrop color and opacity here
          },
        },
      }}
    >
      <Box
        sx={{
          width: width > 767 ? 520 : 378,
          height: width > 767 ? 562 : 453,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            width: width > 767 ? 400 : 340,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: width > 767 ? "48px" : "24px",
              display: "block",
            }}
          >
            Almost There!
          </span>
          <span
            style={{
              fontWeight: 300,
              fontSize: width > 767 ? "20px" : "14px",
              color: "#1B2130BF",
              display: "block",
              marginBottom: "24px",
            }}
          >
            Login to see a result
          </span>

          <form onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Name
              </FormHelperText>
              <TextField
                error={!userIsValid && !isUserPristine}
                placeholder="Your Name"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ marginTop: 0 }}
                value={username}
                onChange={(e) => handleUsername(e)}
                onBlur={handleUserBlur}
                helperText={
                  !userIsValid && !isUserPristine
                    ? "Enter a valid username"
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Email Address
              </FormHelperText>
              <TextField
                error={!emailIsValid && !isEmailPristine}
                placeholder="example@gmail.com"
                variant="outlined"
                fullWidth
                margin="normal"
                onBlur={handleEmailBlur}
                sx={{ marginTop: 0 }}
                value={email}
                onChange={(e) => handleEmail(e)}
                helperText={
                  !emailIsValid && !isEmailPristine ? "Enter a valid email" : ""
                }
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Password
              </FormHelperText>
              <TextField
                error={!isPasswordPristine && !passwordIsValid}
                placeholder="password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ marginTop: 0 }}
                value={password}
                onBlur={handlePasswordBlur}
                onChange={(e) => handlePassword(e)}
                helperText={
                  !isPasswordPristine && !passwordIsValid
                    ? "password must have 8 characters, aleast - 1 cap, 1 small and 1 special characters"
                    : ""
                }
              />
            </FormControl>
            {incorrect && (
              <Alert severity="error">
                User Already Registered, login Instead
              </Alert>)
            }
            { ( serverError && <Alert severity="error">
                Internal sever Error
              </Alert>)}
            {correct && (
              <Alert severity="success">successfully Registered the user</Alert>
            )}
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                size="small"
                sx={{ textTransform: "capitalize", color: "#303D88" }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </span>

            <Button
              disabled={!(emailIsValid && passwordIsValid && userIsValid)}
              variant="contained"
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                },

                textTransform: "capitalize",
              }}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default RegistrationModal;
