import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Backdrop,
  FormControl,
  FormHelperText,
  Alert,
  CircularProgress
} from "@mui/material";
import axios from "axios";
import useWindowSize from "../../Hooks/useWindowSize";

function ContactModal({ open, handleClose }) {
  const [username, setUsername] = useState("");
  const [email,setEmail] = useState("")
  const [phone, setPhone] = useState("");
   const [emailIsValid, setEmailIsValid] = useState(false);
    const [userIsValid, setUserIsValid] = useState(false);
   const [phoneIsValid, setPhoneIsValid] = useState(false);
   const [incorrect, setIncorrect] = useState(false);
   const [correct, setCorrect] = useState(false);
   const [isEmailPristine, setIsEmailPristine] = useState(true);
   const [isUserPristine, setIsUserPristine] = useState(true);
   const [isphonePristine, setIsPhonePristine] = useState(true);
   const [isLoading,setIsLoading] = useState(false)

   const { width } = useWindowSize();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData()
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    formData.append("username", email);
    formData.append("name", username);
    formData.append("contact_number", phone);

    setIsLoading(true)
    setIncorrect(false)
    setCorrect(false)
    try {
      const response = await axios.post(
        "https://asia-south1-serene-essence-419307.cloudfunctions.net/data_extraction_contact",
        formData,
        {
          headers: headers,
        }
      );

      setIsLoading(false)
      if(response.status === 200){
        setIncorrect(false)
        setCorrect(true);
        setTimeout(() => {
          handleClose();
        }, 2000);
        
      }else {
      
        setIncorrect(true);
        setCorrect(false)
      }
    } catch (error) {
      // Handle error response
      setIncorrect(true);
      console.error("Error registering user! try again:", error);
      setIsLoading(false)
    }
  };

 
   const handleEmail = (event) => {
     const entry = event.target.value;
     setEmail(entry);
     if (isEmailPristine) {
       setIsEmailPristine(false);
     }
     if (entry.length > 0 && entry.includes("@")) {
       setEmailIsValid(true);
     } else {
       setEmailIsValid(false);
     }
   };

    const handleUsername = (event) => {
      const entry = event.target.value;
      setUsername(entry);
      if (isUserPristine) {
        setIsUserPristine(false);
      }
      if (entry.length > 0) {
        setUserIsValid(true);
      } else {
        setUserIsValid(false);
      }
    };

     const handleEmailBlur = () => {
       // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
       if (isEmailPristine) {
         setIsEmailPristine(false);
       }
     };

     const handleUserBlur = () => {
       // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
       if (isUserPristine) {
         setIsUserPristine(false);
       }
     };

      const handlePhoneBlur = () => {
        // Optionally, you can set isPristine to false on blur if you want to mark the field as touched
        if (isphonePristine) {
          setIsPhonePristine(false);
        }
      };

      const handlePhone = (event) => {
        const entry = event.target.value;
        const strongPhoneRegex = /^\+?[1-9]\d{1,14}$/
;
        setPhone(entry);
        if (isphonePristine) {
          setIsPhonePristine(false);
        }

        if (strongPhoneRegex.test(entry)) {
          setPhoneIsValid(true);
        } else {
          setPhoneIsValid(false);
        }
      };

  return (
    <Modal
      open={open}
      slots={{ backdrop: Backdrop }}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.75)", // Customize the backdrop color and opacity here
          },
        },
      }}
    >
      <Box
        sx={{
          width: width > 767 ? 520 : 328,
          height: width > 767 ? 562 : 450,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            width: width > 767 ? 400 : 340,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: width > 767 ? "48px" : "24px",
              display: "block",
            }}
          >
            Get a call back!
          </span>
          <span
            style={{
              fontWeight: 300,
              fontSize: width > 767 ? "20px" : "14px",
              color: "#1B2130BF",
              display: "block",
              marginBottom: "24px",
              paddingLeft: "10px", 
            }}
          >
            You'll hear from us soon.
          </span>

          <form onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Name
              </FormHelperText>
              <TextField
                error={!userIsValid && !isUserPristine}
                placeholder="Registered Name"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ marginTop: 0 }}
                value={username}
                onChange={(e) => handleUsername(e)}
                onBlur={handleUserBlur}
                helperText={
                  !userIsValid && !isUserPristine
                    ? "Enter a valid username"
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                phone
              </FormHelperText>
              <TextField
                error={!isphonePristine && !phoneIsValid}
                placeholder="phone"
                type="phone"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ marginTop: 0 }}
                value={phone}
                onBlur={handlePhoneBlur}
                onChange={(e) => handlePhone(e)}
                helperText={
                  !isphonePristine && !phoneIsValid
                    ? "valid phone number is required"
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "24px" }}>
              <FormHelperText sx={{ mb: 1, margin: 0, padding: 0 }}>
                Email Address
              </FormHelperText>
              <TextField
                error={!emailIsValid && !isEmailPristine}
                placeholder="Registered Email Address"
                variant="outlined"
                fullWidth
                margin="normal"
                onBlur={handleEmailBlur}
                sx={{ marginTop: 0 }}
                value={email}
                onChange={(e) => handleEmail(e)}
                helperText={
                  !emailIsValid && !isEmailPristine ? "Enter a valid email" : ""
                }
              />
            </FormControl>

            {incorrect && (
              <Alert severity="error">
                Unable to process this request
              </Alert>
            )}
            {isLoading && <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress />
              </Box>}
            {correct && (
              <Alert severity="success">
                successfully Submitted your request
              </Alert>
            )}

            <Button
              disabled={!(emailIsValid && phoneIsValid && userIsValid)}
              variant="contained"
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A", // Slightly darker shade for hover effect
                },

                textTransform: "capitalize",
              }}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default ContactModal;
