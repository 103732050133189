import React, { useEffect, useRef, useState } from "react";
import styles from "./Result.module.css";
import { Box, Button } from "@mui/material";
import { Download } from "@mui/icons-material";
import useWindowSize from "../Hooks/useWindowSize";
import exportFromJSON from "export-from-json";

function Result(props) {
  const { width } = useWindowSize();
  const [isPdf, setIsPdf] = useState(false);

  useEffect(() => {
    const extension = props.fileName.split(".").pop().toLowerCase();
    setIsPdf(extension === "pdf");
  }, [props.fileName]);

  const handleDownload = () => {
    const jsonString = JSON.stringify(props.data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "output.json");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCSVDownload = () => {
    const fileName = "download";
    const exportType = exportFromJSON.types.csv;
    const data = [props.data];
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <>
      <div className={styles["result-section"]}>
        <span className={styles["result-heading"]}>Result</span>
        {width > 800 && (
          <div className={styles["result-buttons"]}>
            <Button
              variant="outlined"
              sx={{ color: "#303D88" }}
              startIcon={<Download />}
              onClick={handleDownload}
            >
              JSON File
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#303D88",
                "&:hover": {
                  backgroundColor: "#252E6A",
                },
              }}
              startIcon={<Download />}
              onClick={handleCSVDownload}
            >
              CSV File
            </Button>
          </div>
        )}
      </div>
      <div className={styles["result-output-cover"]}>
        <div className={styles["result-output"]}>
          <Box className={styles["result-output-left"]}>
            <div className={styles["media-container"]}>
              {isPdf ? (
                <embed
                  src={props.fileUrl}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  className={styles["pdf-embed"]}
                />
              ) : (
                <img
                  src={props.fileUrl}
                  alt={props.fileName}
                  width="100%"
                  height="100%"
                  className={styles["jpeg-image"]}
                />
              )}
              <div className={styles["gradient-overlay"]}></div>
            </div>
            <div className={styles["result-pdf-details"]}>
              <span className={styles.span1}>{props.fileName}</span>
              <span className={styles.span2}>{props.docSize} MB File</span>
            </div>
          </Box>
          <Box className={styles["result-output-right"]}>
            <span
              style={{
                lineHeight: "36px",
                color: "#C66D00",
                fontWeight: 300,
                fontSize: width > 800 ? "20px" : "14px",
              }}
            >
              <pre>{JSON.stringify(props.data, null, 2)}</pre>
            </span>
          </Box>
        </div>
      </div>
      {width < 800 && (
        <div className={styles["result-buttons"]}>
          <Button
            variant="outlined"
            sx={{ color: "#303D88" }}
            startIcon={<Download />}
            onClick={handleDownload}
          >
            JSON File
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#303D88",
              "&:hover": {
                backgroundColor: "#252E6A",
              },
            }}
            startIcon={<Download />}
            onClick={handleCSVDownload}
          >
            CSV File
          </Button>
        </div>
      )}
    </>
  );
}

export default Result;
